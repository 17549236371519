import {
  purchase_requirement_type_enum,
  purchase_requirement_status,
  GetPurchaseRequirementsQuery,
  enum_consent_policy_type_enum,
  enum_stripe_product_type_enum,
} from '../../../../generated/graphql'
import { typesafeEntries } from '../../../../typescript/guards/typesafeEntries'
import {
  atHomeLabsDrawRequirementShownForCartIdSessionStorageKey,
  currentCartShippingDetailsConfirmedSessionStorageKey,
} from '../../../../common/constants/sessionStorage'

type ConsentPolicyVersion = {
  id: string
  title?: string
  content?: string
  external_url?: string
}
export type ConsentPolicy = { id: string; name?: string; version: ConsentPolicyVersion }

type NormalRequirement = { step: requirementsSteps }

type ConsentRequirement = NormalRequirement & {
  policies: ConsentPolicy[]
}

type Requirement = NormalRequirement | ConsentRequirement

export enum requirementsSteps {
  personalInfo = 'personalInfo',
  contactInfo = 'contactInfo',
  productLevelConsentPolicies = 'productLevelConsentPolicies',
  atHomeLabDraw = 'atHomeLabDraw',
  prescriberApproval = 'prescriberApproval',
}

type Status = { incomplete: boolean }

export type StatusWithConsentPolicies = Status & { serviceLevelConsentPolicies: ConsentPolicy[] }
export type StatusWithProductPurchaseRequirements = Status & { missingProductTypes: enum_stripe_product_type_enum[] }
export type Requirements = RequirementsState['requirements']

export type RequirementsState = {
  requirements: {
    steps: {
      [requirementsSteps.personalInfo]: StatusWithConsentPolicies
      [requirementsSteps.contactInfo]: StatusWithConsentPolicies & { isReorderConfirmation: boolean }
      [requirementsSteps.productLevelConsentPolicies]: ConsentPolicy[]
      [requirementsSteps.atHomeLabDraw]: Status
      [requirementsSteps.prescriberApproval]: Status
    }
    unmetProductRequirements: Set<enum_stripe_product_type_enum>
    todoList: Requirement[]
    pending: Requirement[]
  }
}

const toDoListStepOrder = [
  requirementsSteps.personalInfo,
  requirementsSteps.contactInfo,
  requirementsSteps.productLevelConsentPolicies,
  requirementsSteps.atHomeLabDraw,
]

export const defaultRequirementsStateFactory = (): RequirementsState => {
  const defaultState = {
    requirements: {
      steps: {
        [requirementsSteps.personalInfo]: { incomplete: false, serviceLevelConsentPolicies: [] as ConsentPolicy[] },
        [requirementsSteps.contactInfo]: {
          incomplete: false,
          serviceLevelConsentPolicies: [] as ConsentPolicy[],
          isReorderConfirmation: false,
        },
        [requirementsSteps.productLevelConsentPolicies]: [] as ConsentPolicy[],
        [requirementsSteps.atHomeLabDraw]: { incomplete: false },
        [requirementsSteps.prescriberApproval]: { incomplete: false },
      },
      unmetProductRequirements: new Set() as Set<enum_stripe_product_type_enum>,
      todoList: [] as Requirement[],
      pending: [] as Requirement[],
    },
  }
  return defaultState
}

// TODO consider killing this mapping. seems like we are moving towards a one to one so i can just use the existing
// enums instead of mapping them to custom steps/requirement groups
const purchaseRequirementMap = {
  [purchase_requirement_type_enum.patient_details]: [requirementsSteps.personalInfo],
  [purchase_requirement_type_enum.shipping_address]: [requirementsSteps.contactInfo],
  [purchase_requirement_type_enum.consent]: [requirementsSteps.productLevelConsentPolicies],
} as Record<purchase_requirement_type_enum, requirementsSteps[]>

type Flags = {
  atHomeLabDrawIntakeIsEnabled: boolean
  consentRequirementIntakeIsEnabled: boolean
  isUseVitalContentEnabled: boolean
}

// const purchaseRequirementDisplayTextDict = {
//   [purchase_requirement_type_enum.consent]: 'consent forms',
//   [purchase_requirement_type_enum.patient_details]: 'patient details',
//   [purchase_requirement_type_enum.prescriber_approval]: 'prescriber approval',
//   [purchase_requirement_type_enum.realize_account]: 'create an account',
//   [purchase_requirement_type_enum.shipping_address]: 'shipping',
// } as Record<purchase_requirement_type_enum, string>

type Input = {
  purchaseRequirements: GetPurchaseRequirementsQuery['get_purchase_requirements'] | undefined
  containsLabs: boolean
  realizeTos: ConsentPolicy | null
  cartId: string | null
  flags: Flags
}
export function purchaseRequirementsToRequirementsState({
  purchaseRequirements,
  realizeTos,
  containsLabs,
  cartId,
  flags,
}: Input) {
  const { requirements } = defaultRequirementsStateFactory()

  // show at home lab draw once per session when a user checks out with labs
  requirements.steps[requirementsSteps.atHomeLabDraw].incomplete =
    sessionStorage.getItem(atHomeLabsDrawRequirementShownForCartIdSessionStorageKey) !== String(cartId) &&
    containsLabs &&
    flags.atHomeLabDrawIntakeIsEnabled

  // TODO clean this up with more of a switch statement type refactor to make each requirement's processing more quickly discernable
  ;(purchaseRequirements ?? []).forEach((x) => {
    if (!('status' in x)) return

    if (x.status !== purchase_requirement_status.met) {
      if (x.__typename === 'ProductTypePurchaseRequirementModel') {
        const { productType } = x
        if (productType) {
          requirements.unmetProductRequirements.add(productType)
        }
        return
      }

      if (
        x.status === purchase_requirement_status.pending &&
        x.type === purchase_requirement_type_enum.prescriber_approval
      ) {
        requirements.steps[requirementsSteps.prescriberApproval].incomplete = true
        return
      }

      if (x.__typename === 'ConsentPurchaseRequirementModel') {
        if (!flags.consentRequirementIntakeIsEnabled || !x.consent_policy) return
        const policy = x.consent_policy

        const { id, name, versions, policy_type } = policy ?? {}

        const filtered = (versions ?? []).filter((v) => v.id)
        filtered.forEach((fv) => {
          const policyData = { id, name, version: fv }
          if (policy_type === enum_consent_policy_type_enum.SERVICE_LEVEL) {
            // this should never happen, but if it does, the link will not show up and the user will never be able to
            // complete the step
            if (!policyData.version.external_url) {
              requirements.steps[requirementsSteps.productLevelConsentPolicies].push(policyData)
            } else {
              requirements.steps[requirementsSteps.personalInfo].incomplete = true
              requirements.steps[requirementsSteps.personalInfo].serviceLevelConsentPolicies.push(policyData)
            }
          } else {
            requirements.steps[requirementsSteps.productLevelConsentPolicies].push(policyData)
          }
        })
      } else {
        purchaseRequirementMap[x.type]?.forEach((step) => {
          const stepStatus = requirements.steps[step]
          if (!Array.isArray(stepStatus)) {
            stepStatus.incomplete = true
          }
        })
      }
    }
  })

  // even if the requirement is met, we want to force confirmation for all orders. if we have not saved a session
  // storage key for the confirmation whose value, matches the current cart, that means we still need to confirm for
  // this cart
  if (sessionStorage.getItem(currentCartShippingDetailsConfirmedSessionStorageKey) !== cartId) {
    // if we do not have a contactInfo step added yet, that means this requirement is met on BE, but we need to force
    // it, so set incomplete to true (so the form shows), and set isReorderConfirmation to true so we can show the
    // currect confirmation UI. if the contactInfo is already set to incomplete, we don't need to do anything and the
    // correct initial contact info details will show
    if (requirements.steps[requirementsSteps.contactInfo].incomplete === false) {
      requirements.steps[requirementsSteps.contactInfo].isReorderConfirmation = true
      requirements.steps[requirementsSteps.contactInfo].incomplete = true
    }
  }

  // factor in the realize TOS
  if (realizeTos) {
    // if personal info incomplete, add TOS there
    if (requirements.steps[requirementsSteps.personalInfo].incomplete) {
      requirements.steps[requirementsSteps.personalInfo].serviceLevelConsentPolicies.unshift(realizeTos)
      // if there is contact info and no personal, add to contact info
    } else if (requirements.steps[requirementsSteps.contactInfo].incomplete) {
      requirements.steps[requirementsSteps.contactInfo].serviceLevelConsentPolicies.unshift(realizeTos)
    }
    // if there is neither contact nor personal, set personal to incomplete and add there
    else {
      requirements.steps[requirementsSteps.personalInfo].incomplete = true
      requirements.steps[requirementsSteps.personalInfo].serviceLevelConsentPolicies.unshift(realizeTos)
    }
  }

  requirements.todoList = typesafeEntries(requirements.steps)
    .filter(
      ([step, status]) =>
        step !== requirementsSteps.prescriberApproval &&
        (Array.isArray(status) ? status.length : status.incomplete === true)
    )
    .map(([step, status]) => {
      const general = { step }
      if (step === requirementsSteps.productLevelConsentPolicies) {
        return { ...general, policies: status }
      }
      return general
    })
    .sort((a, b) => toDoListStepOrder.indexOf(a.step) - toDoListStepOrder.indexOf(b.step))

  requirements.pending = typesafeEntries(requirements.steps)
    .filter(([step, status]) => step === requirementsSteps.prescriberApproval && status.incomplete === true)
    .map(([step]) => ({ step }))

  return requirements
}
